:root {
  --color-primary: #38ad10;
  --color-primary-light: #41ca12;
  --color-primary-very-light: #eafce4;
  --color-secondary: #fff;
  --color-grey: #4a545e;
  --color-grey-light: #9ea8b3;

  --gap-small: 1rem;
  --gap-medium: 2rem;
  --gap-large: 4rem;

  --font-size-small: 0.875rem;
  --font-size-medium: 1rem;
  --font-size-large: 1.25rem;

  --font-size-heading-small: 2rem;
  --font-size-heading-medium: 4rem;
  --font-size-heading-large: 7rem;

  --font-weight-light: 400;
  --font-weight-medium: 600;
  --font-weight-heavy: 700;

  --nav-height: 68px;

  --bs-primary: var(--color-primary); /* TODO probably obsolete */
  --bs-secondary: var(--color-secondary); /* TODO probably obsolete */
  --bs-link-color: black;
  --bs-link-hover-color: var(--color-primary-light);
}

html body {
  --bs-body-bg: #f5f7f9;
  --bs-body-font-family: 'Inter';

  margin: 0;
  line-height: 1.5;
}

a {
  /* TODO maybe add underlines */
  text-decoration: underline;

  /* TODO probably obsolete */
  &.active {
    color: var(--color-primary);
  }
}

img {
  max-inline-size: 100%;
  block-size: auto;
  inline-size: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1;
}

p {
  margin: 0;
}

.lumiron-time-popup {
  max-height: 200px;
  overflow-y: scroll;
}
